.aboutmask{
    width: 100vw;
    height: 100vh;
    position: relative;
}

.aboutland{
    height: 100vh;
    width: 100vw;
    background-color: rgba(119, 136, 153);
    /* background-color: rgba(197, 156, 159); */
}

.aboutland .content{
    position: relative;
    text-align: left;
    padding-top: 150px;
    left: 5%;
    right: 10%;
    width: 85vw;
}

.hexagon{
    color:rgba(255,255,255);
    margin-right: 1rem;
    float: right;
}

.aboutland .aboutmask .content h1{
    padding-bottom: 30px;
}

.aboutland .aboutmask .content h3{
    font-weight: 400;
    color: #fff;
    padding-bottom: 10px;
    font-size: rem;
}