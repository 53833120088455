
.projectsmask{
    width: 100vw;
    height: min-height;
    padding-top: 10%;
    position: relative;
}

.mask::after{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
 
.projectsland{
    width: 100vw;
    height: min-height;
    background-color: rgba(46,46,46,1);;
}

.projectsland .projectsmask .content{
    position: relative;
    text-align: left; 
    top: 20%;
    left: 5%;
    right: 10%;
    width: 85vw;
    padding: 20px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }
  
.card {
    flex: 0 0 calc(50% - 15px);
    max-width: 0 0 calc(50% - 15px);
    width: 100vw;
    background: rgba(119, 136, 153, 0.7);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px; 
    transition: 0.3s;
    border: 2px solid transparent;
    box-sizing: border-box;
}
  
@media screen and (max-width: 640px) {
    .projectsland .content {
        margin-bottom: 50px; /* Adjust padding for smaller screens */
      }
    

    .cards {
      justify-content: center;
    }
  
    .card {
      flex: 1 1 calc(100% - 40px);
      /* margin: 10px 0; */
    }
}

.card p{
    padding-bottom: 0;
}

.card:hover{
    border-color: rgba(119, 136, 153);
    background-color: rgba(46,46,46,1);
}

.prjtbtn{
    border-radius: 7px;
    font-size: 0.7rem;
    padding: 10px 18px;
    text-transform: lowercase;
    background: rgba(46,46,46,0.5);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.prjtbtn:hover{
    background: rgba(119, 136, 153);
    color: rgba(46, 46, 46);
}

.projectsland .content h3{
    color: #fff;
    padding-bottom: 30px;
}
.projectsland .content h1{
    padding-bottom: 30px;
}

.projectsland .content h2{
    padding-bottom: 50px;
    font-weight: 400;
    color: #fff;
    font-size: 1.5rem;
}


.content .btns{
    position: relative;
    text-align: center;
}

@media screen and (max-width:640px) {
    .projectsland .content h1{
        font-size: 2rem;
    }
    
}

