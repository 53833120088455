

.viewimg{
    width: 100%;
    height: 100vh;
    background: #2e2e2e;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.grid{
    padding-top: 90px;
}

.img{
    width: auto;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 90%;
    max-height: 90%;
}

.cross{
    size: 35px;
    color: #fff;
    bottom: 300px;
}