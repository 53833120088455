.hmmask{
    width: 100%;
    height: 100vh;
    position: relative;
}


.hmland{
    display: flex;
    justify-content: center;
    align-items: center;
    height: min-height;
    width: 100%;
}

.hmland .hmmask .hmcontent{
    position: relative;
    /* text-align: left; */
    top: 20%;
    left: 15%;
    right: 10%;
    width: 85vw;
    padding: 20px;
}

.hmcontent {
    width: 60vw; /* Width to match your .aboutland .content width */
    max-width: 80vw; /* Maximum width */
    max-height: 80vw;
    background: rgba(119, 136, 153); /* Slightly transparent dark background */
    padding: 0rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    justify-content: center;
    flex-direction: column;
    flex: 1;
  }
  
  .hmcontent h1 {
    font-size: 2rem;
    padding-bottom: 1vw;
  }

  .hmbtn {
    border-radius: 7px;
    font-size: 1rem;
    padding: 10px 18px;
    text-transform: uppercase;
    background: rgba(46,46,46,0.5);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .hang-man-img {
    width: 20%; /* Adjust size */
    max-width: 300px; /* Ensure the image does not get too large */
    height: auto; /* Maintain aspect ratio */
  }


.hmbtn:hover{
    background: rgba(46, 46, 46, 0.2);
    color: #fff;
    transition: 0.3s;
}

.btn-light:hover{
    background: rgba(119, 136, 153, 0.7);
    color: #fff;
}

@media screen and (max-width:640px) {
    .hmland .content h1{
        font-size: 2rem;
    }
    
}

