.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}

.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    /* opacity: 0.5; */
}

.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #808080;
    opacity: 0.5;
}

.land{
    height: 100%;
    width: 100%;
}

.land .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.land .content h1{
    font-size: 3rem;
    padding: 0.6rem 0 1.5rem;
}

.content .btn{
    margin: 1rem 0.2rem;
}

.btn:hover{
    background: rgba(46, 46, 46, 0.2);
    color: #fff;
    transition: 0.3s;
}

.btn-light:hover{
    background: rgba(119, 136, 153, 0.7);
    color: #fff;
}

@media screen and (max-width:640px) {
    .land .content h1{
        font-size: 2rem;
    }
    
}

