.footer{
    width: 100%;
    padding: 2rem 0;
    background-color: rgba(46,46,46,1);
    text-align: center;
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.socials{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
    text-align: center;
    margin: auto;
}

p{
    font-size: 0.8rem;
    color: rgba(255,255,255,0.8);
}
