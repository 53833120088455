.ContactForm {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: rgba(119, 136, 153); /* The grayish color from your .aboutland */
    padding-top: 150px; /* Same top padding as your .aboutland .content */
    color: #fff; /* White text to contrast the background */
    display: flex;
    align-items: center;
    justify-content: center;
  }

.contactland{
  width: 100vw;
  height: min-height;
  /* background-color: rgba(46,46,46,1);; */
}

.contactmask{
  width: 100vw;
  /* height: min-height; */
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
  margin-top: -10%;
}

.mask::after{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

/* 
  .ContactForm h1{
    padding-bottom: 30px;
    padding-right: 30%;
} */
  
  .contact-form {
    width: 85vw; /* Width to match your .aboutland .content width */
    max-width: 80vw; /* Maximum width */
    max-height: 80vw;
    min-height: fit-content;
    background: rgba(46, 46, 46, 0.8); /* Slightly transparent dark background */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    justify-content: center;
  }
  
  .contact-form h1 {
    font-size: 2rem;
    padding-bottom: 2vw;
  }
  .input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Space between label and input */
    width: 100%; /* Make the container full width */
}

  .contact-form label {
    margin: .3rem 0rem .1rem 0rem;
    /* margin-bottom: .1rem;
    margin-top: .3rem; */
    font-weight: 400; /* Match the weight from your .aboutland .aboutmask .content h3 */
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    padding: 0.5rem;
    border: 1px solid #fff; /* White border for inputs */
    background-color: transparent; /* Transparent background for inputs */
    color: #fff; /* White text for inputs */
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .contact-form textarea {
    height: 150px;
    resize: vertical;
  }
  
  .contact-form button {
    border-radius: 7px;
    font-size: 1rem;
    padding: 10px 18px;
    /* text-transform: lowercase; */
    background: rgba(46,46,46,0.5);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .contact-form button:hover {
    background: rgba(119, 136, 153);
    color: rgba(46, 46, 46);/* Slightly transparent on hover */
  }
  


  @media (max-width: 768px) {
    /* .contact-page {
      padding-top: -2%;
    } */
    .ContactForm {
      padding: 1rem; 
    }
    .contact-form {
      min-height: fit-content;
    }

    .contactland {
      margin-top: 15%;
    }
    .contactmask {
      height: auto;
      max-width: 90%; 
      padding: 1.5rem;
    }
  
    .contact-form h1 {
      width: 90vw;
      font-size: 1.5rem;
    }
  
    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form textarea {
      font-size: 0.9rem; 
    }
  
    .contact-form button {
      font-size: 0.9rem;
      justify-content: center;
    }
  }
  
  .contact-form p {
    padding-top: 1.5%;
  }