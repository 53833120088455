/* .aboutmask{
    width: 100vw;
    height: 100vh;
    position: relative;
} */

.hannahland{
    height: 100;
    width: 100vw;
    /* background-color: rgba(119, 136, 153); */
    background-color: rgba(197, 156, 159, 0.8);
}

.hannahland .content{
    position: relative;
    text-align: left;
    padding-top: 150px;
    left: 5%;
    right: 10%;
    width: 85vw;
}

.hannahland .hannahmask .content h1{
    padding-bottom: 30px;
}

.hannahland .hannahmask .content h3{
    font-weight: 400;
    color: #fff;
    padding-bottom: 10px;
    font-size: 1.5rem;
}


.hannahcards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjusted to space-around for better centering */
  }
  
  .hannahcard {
    width: 200px; /* Set the width of each card */
    height: 200px; /* Set the height of each card */
    margin: 10px; /* Adjust the margin based on your preference */
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s;
    border: 2px solid transparent;
    box-sizing: border-box;
  }
  
  .hannahcard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px; /* Add border-radius for rounded corners */
  }
  
  .hannahprjtbtn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(46, 46, 46, 0.5);
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  @media screen and (max-width: 640px) {
    .hannahcards {
      justify-content: center;
    }
  
    .hannahcard {
      width: 80%; /* Adjusted width for smaller screens */
      flex: 1 1 calc(100% - 40px);
    }
  }

/* .hannahcards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }
  
.hannahcard {
    flex: 0 0 calc(50% - 15px);
    max-width: 0 0 calc(50% - 15px);
    width: 100vw;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px; 
    transition: 0.3s;
    border: 2px solid transparent;
    box-sizing: border-box;
}
  
@media screen and (max-width: 640px) {
    .hannahcards {
      justify-content: center;
    }
  
    .hannahcard {
      flex: 1 1 calc(100% - 40px);
    }
}


.hannahprjtbtn{
    border-radius: 7px;
    font-size: 0.7rem;
    padding: 10px 18px;
    text-transform: lowercase;
    background: rgba(46,46,46,0.5);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
} */